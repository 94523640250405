.Paciente {
  display: flex;
  flex-direction: column;
}

.PacienteContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Feature {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
  justify-content: space-between;
  padding: 64px 128px;
}

.Feature > div {
  display: flex;
  flex-direction: column;
  gap: 18px;
  align-items: start;
}

.Feature__Title {
  font-size: 36px;
  line-height: 52px;
  color: #212121;
  margin: 0px;
}

.Feature__Description {
  font-size: 16px;
  line-height: 32px;
  color: #90A3B4;
  margin: 0px;
}

.ContactSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px 128px;
  gap: 16px;
}

.ContactCard {
  display: flex;
  flex-direction: row;
  padding: 32px 74px;
  border-radius: 8px;
  background-color: #2864AE;
}

.ContactCard__Title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.ContactCard__Title > span {
  font-size: 36px;
  line-height: 44px;
  color: #ffffff;
}

.ContactCard__Form {
  display: flex;
  flex-direction: column;
  align-items: end;
  padding: 0px 32px;
  gap: 10px;
  align-items: stretch;
  flex: 1;
}

.Row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex: 1;
}

.Field {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.Label {
  color: #E3F0FF;
  font-size: 16px;
  line-height: 20px;
  padding: 4px 0px;
}

.Input {
  background-color: #ffffff;
  color: #212121;
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 20px;
  font-family: 'CNSBrasil';
}

.Button {
  background-color: #ffffff;
  color: #2864AE;
  border-radius: 8px;
  padding: 12px 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px;
  font-family: 'CNSBrasil';
  border: none;
  cursor: pointer;
}
