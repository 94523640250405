.AppHeader {
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding-top: 32px;
  background-color: #2864AE;
  flex-grow: 1;
}

.AppPresentation {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 32px 0px;
}

.AppPresentation > div {
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 1;
}

.AppPresentation > div:first-child {
  padding-left: 128px;
  align-items: start;
}

.AppPresentation > div:last-child {
  padding-right: 128px;
  align-items: end;
}

.AppPresentation__Title {
  font-size: 64px;
  line-height: 76px;
  color: #ffffff;
}

.AppPresentation__Description {
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
}

.AppPresentation__AppLinks {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px;
  align-items: center;
}

