@font-face {
  font-family: 'CNSBrasil';
  src: url('./fonts/CNSBrasil-Regular.ttf');
}

html, body {
  margin: 0;
  font-family: 'CNSBrasil', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: 'CNSBrasil', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.MainNavTabItem {
  color: #ffffff;
}

.MainNavTabItem > span {
  color: #ffffff !important;
}

.MainNavTabItem::after {
  background: #36B37E !important;
}

.Spacer {
  flex-grow: 1;
}