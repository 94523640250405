.AppNav {
  display: flex;
  flex-direction: row;
  padding: 0px 128px;
  justify-content: space-between;
  align-items: center;
}

.AppLogo {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 0px 12px;
  justify-content: start;
  align-items: center;
}

.AppLogo > span {
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
}
