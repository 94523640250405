.TabList {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.TabItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.TabItem > span {
  padding: 12px 0px;
  color: #efefef;
  font-size: 16px;
  line-height: 20px;
}

.TabItem > .ActiveMarker {
  height: 4px;
  width: 100%;
  background-color: #efefef;
  border-radius: 2px;
}
